import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2cb1173d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { body: "card" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["value", "selected"]
const _hoisted_4 = ["selected"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "card-image",
      src: _ctx.imageUrl
    }, null, 8, _hoisted_2),
    _createElementVNode("button", {
      class: "remove-button",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.removeCard()))
    }, "Remove"),
    _withDirectives(_createElementVNode("select", {
      class: "card-selects",
      id: "set-select",
      name: "set",
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedSet) = $event))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sets, (cardSet) => {
        return (_openBlock(), _createElementBlock("option", {
          key: cardSet.scryfallId,
          value: cardSet.set,
          selected: cardSet.set === _ctx.selectedSet
        }, _toDisplayString(cardSet.set), 9, _hoisted_3))
      }), 128))
    ], 512), [
      [_vModelSelect, _ctx.selectedSet]
    ]),
    _withDirectives(_createElementVNode("select", {
      class: "card-selects",
      id: "quantity-select",
      name: "quantity",
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.cardQuantity) = $event))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.numbersForQuantity, (number) => {
        return (_openBlock(), _createElementBlock("option", {
          key: number,
          selected: _ctx.quantity === number
        }, _toDisplayString(number), 9, _hoisted_4))
      }), 128))
    ], 512), [
      [_vModelSelect, _ctx.cardQuantity]
    ])
  ]))
}