
import { defineComponent } from 'vue';
import { getScryfallCard,  getScryFallCardsLike} from '@/Helpers/ScryfallHelper'
import { cardStore } from '@/Stores/store';
import LoadingComponent from './LoadingComponent.vue';

interface NameAndImage {
  name: string,
  imageURL: string | undefined
}

const __default__ = defineComponent({
    name: "NavbarComponent",
    components: {
      LoadingComponent
    },
    methods: {
      async getUrl(name: string) {
        return (await getScryfallCard(name, false))?.image_uris.small;
      },
      search() {
      this.searchedClickedAtLeastOnce = true;
      this.navbarPadding = '4px'
      this.searchResults = this.inputValue
      }
    },
    watch: {
    async isInputFocused(newValue) {
      if (this.searchedClickedAtLeastOnce) {
        this.navbarPadding = newValue ? '4px' : '1.25rem'
      }
    },
    async cardToAdd() {
      if (this.cardToAdd) {
        await this.store.addCard(this.cardToAdd, false);
        this.cardToAdd = "";
      }
    },
    async searchResults(newValue) {
      this.loading = true;
      this.currentSearchResults = [] as NameAndImage[];
      const response = await getScryFallCardsLike(newValue);
      const result = response.data;
      if (result.length <= 0) {
        let thingToReturn: NameAndImage[] = []
        thingToReturn.push({
          name: "No Result",
          imageURL: undefined
        })
        this.loading = false;
        return thingToReturn;
      }
      let imgsAndURLs: NameAndImage[] = [];
      if (newValue !== null && newValue !== "" && newValue !== undefined) {
        for (let i = 0; i < 5; i++) {
          if (!result[i]) {
            continue;
          }
          const img = await this.getUrl(result[i]);
          if (img == undefined) {
            continue;
          }
          const nameAndImg: NameAndImage = {
            name: result[i],
            imageURL: img
          }
          imgsAndURLs.push(nameAndImg);
        }
        this.currentSearchResults = imgsAndURLs;
        this.lastSearchDate = new Date();
      }
      this.loading = false;
    }
  },
  data() {
    return {
      inputValue: "",
      searchResults: "",
      currentSearchResults: [] as NameAndImage[],
      lastSearchDate: new Date(),
      isInputFocused: false,
      store: cardStore(),
      cardToAdd: "",
      searchedClickedAtLeastOnce: false,
      navbarPadding: "1.25rem",
      loading: false
    };
  }
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "1f1b88f6": (_ctx.navbarPadding)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__