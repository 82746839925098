import { vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-11659e8b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "navbar dynamic-padding" }
const _hoisted_2 = {
  key: 0,
  class: "searchbar-result"
}
const _hoisted_3 = {
  key: 1,
  class: "searchbar-block noresult-block"
}
const _hoisted_4 = ["onMousedown"]
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingComponent = _resolveComponent("LoadingComponent")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("nav", _hoisted_1, [
      _withDirectives(_createElementVNode("input", {
        placeholder: "Search...",
        class: "navbar-input",
        type: "search",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
        onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.loading ? '' : _ctx.search()), ["enter"])),
        onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isInputFocused = false)),
        onFocus: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isInputFocused = true))
      }, null, 544), [
        [_vModelText, _ctx.inputValue]
      ])
    ]),
    (_ctx.isInputFocused && _ctx.searchedClickedAtLeastOnce)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_LoadingComponent, {
                key: 0,
                class: "loading-bar"
              }))
            : _createCommentVNode("", true),
          (_ctx.currentSearchResults.length < 1 && !_ctx.loading)
            ? (_openBlock(), _createElementBlock("li", _hoisted_3, "No Results"))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentSearchResults, (value) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "searchbar-block",
              onMousedown: _withModifiers(($event: any) => (_ctx.cardToAdd = value.name), ["stop"]),
              key: value.name
            }, [
              (value.imageURL)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    class: "searchbar-block-image",
                    src: value.imageURL
                  }, null, 8, _hoisted_5))
                : _createCommentVNode("", true),
              _createTextVNode(_toDisplayString(value.name), 1)
            ], 40, _hoisted_4))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}